import React from 'react'
import Layout from '../components/layout'

export default function muchAdoNews1() {
    return (
        <Layout>
            <div style={{padding: "108px 20px 20px 10px", width: 400, margin: "0 auto"}}>
                <h1>Messina Messenger</h1>

                <h2>Don John apprehended, Dogberry praised </h2>

                <p>Don John was apprehended yesterday after a high-speed chase in the late afternoon. He had been hiding in plain sight for days, explained Dogberry, who is credited with bringing the real villain behind the scandal to justice. 

When he spotted Don John attempting to flee town on a moped, Dogberry jumped on the back and subdued the suspect. Don John is being held in the Messina jail while he awaits his court date. Margaret asserts that she will file additional civil charges against him.

Dogberry has been hailed as a hero and will receive a key to the city next week. When asked about the upcoming ceremony, he responded, “Great, now I can add locksmith to my services.”
</p>

<h2>Messina welcomes Hero back from the dead </h2>

<p>In a strange, yet welcome turn of events, Hero was seen this morning strolling arm in arm with Claudio. 
The couple sipped espressos from Caffè della Vita as they took in the morning air. The barista who served the couple identified Hero and confirmed that she appeared to be very much alive and well. 
The Messina Messenger is glad to retract our previously published obituary.
</p>

            </div>
        </Layout>
    )
}